import React from 'react';
import HeroSplit from '../components/sections/HeroSplit';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesTabs from '../components/sections/FeaturesTabs';
import News from '../components/sections/News';
import Roadmap from '../components/sections/Roadmap';
import Pricing from '../components/sections/Pricing';
import Cta from '../components/sections/Cta';
import HeroSlider from '../components/sections/HeroSlider';
import '../App.css';
import ShopperForm from '../components/sections/ShopperForm';
import img1 from '../assets/images/african_bg.jpg';
import img2 from '../assets/images/splash-screen-2.png';
import classNames from 'classnames';
import Image from '../components/elements/Image';
import About from '../components/sections/about';
import GenericSection from '../components/sections/GenericSection';
import SectionHeader from '../components/sections/partials/SectionHeader';
import Button from '../components/elements/Button';
import Modal from '../components/elements/Modal';
import Input from '../components/elements/Input';

class Home extends React.Component {
  state = {
    demoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {

    const genericSection01Header = {
      title: 'Are you a unique creative hand-made product creator?'
    }    
    const slides = [
{
image: require('../assets/images/vendor.jpg'),
title: 'Vendors',
description: 'You can sell your handmade products on Arena',
// btnText : "Sign Up Now",
btnText : <div style={{ display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
  {/* <a href="https://bit.ly/CAMarena" style={{margin:"10px 10px"}}>
<Button color="secondary " wideMobile={true}>
  Sign Up Now
  </Button>
</a> */}
<a href="https://play.google.com/store/apps/details?id=com.thearenang.arena&pcampaignid=web_share" style={{margin:"10px 10px"}}>
<Button color="secondary " wideMobile={true}>
Download Android Now
  </Button>
</a>
</div>
,
// btnLink : "https://bit.ly/CAMarena",
},
{
  image: require('../assets/images/shoppers.jpeg'),
  title: 'Shoppers',
  description: 'Discover amazing handmade products and vintage items for your personal collections',
  // btnText : "Join the waitlist",
  btnText : <div style={{ display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
  {/* <a href="https://bit.ly/CAMarena" style={{margin:"10px 10px"}}> */}
  {/* <a href="#" style={{margin:"10px 10px"}}>
<Button color="secondary " wideMobile={true}>
  ios is Coming
  </Button>
</a> */}
<a href="https://play.google.com/store/apps/details?id=com.thearenang.arena&pcampaignid=web_share " style={{margin:"10px 10px"}}>
<Button color="secondary " wideMobile={true}>
Download Android Now
  </Button>
</a>
</div>,
  // btnLink : "#wailist",
},
// {
// image: 'https://via.placeholder.com/1200x600',
// title: 'Slide 3',
// description: 'Description for Slide 3',
// },
];
    return (
      <React.Fragment>
        {/* <HeroSplit hasBgColor invertColor /> */}

<HeroSlider slides={slides} />;

        {/* <FeaturesTiles /> */}
        <About invertMobile imageFill bottomOuterDivider/>
        {/* <FeaturesTabs topDivider bottomOuterDivider /> */}
        <News className="illustration-section-01" />
        {/* <Roadmap topOuterDivider /> */}
        {/* <Pricing hasBgColor pricingSlider /> */}
        {/* <ShopperForm/> */}
        {/* <GenericSection topDivider> */}
          {/* <div className="container-xs">
            <SectionHeader data={genericSection01Header} className="center-content" tag="h3" />
            <div className="center-content">
              <Button
                color="secondary"
                aria-controls="demo-modal"
                style={{maxWidth:"100%"}}
                onClick={this.openModal}>Sign Up to Showcase Your Products with Us</Button>
            </div> */}
            <Modal
              id="demo-modal"
              show={this.state.demoModalActive}
              handleClose={this.closeModal}
            >
              <div className="center-content">
                <h3 className="mt-16 mb-8">Join the community creative creators to showcase and sell your products</h3>
                <p className="text-sm">
                Do you create hand-made local products across jewelry, clothing, pottery, woodworks, leatherworks, waste works and so on? Do you have vintage collections to sell?
                </p>
              </div>
              {/* <form style={modalFormStyle}>
                <div className="mb-12">
                  <Input
                    type="email"
                    label="This is a label"
                    placeholder="Your best email"
                    labelHidden />
                </div> */}
                <Button onClick={() => window.open("https://bit.ly/CAMarena")} color="primary" wide>Join Now</Button>
              {/* </form> */}
              <div className="center-content mt-24">
                <a
                  className="text-xs"
                  aria-label="close"
                  href="#0"
                  onClick={this.closeModal}
                >No thanks!</a>
              </div>
            </Modal>
          {/* </div > */}
        {/* </GenericSection>  */}
        {/* <Cta hasBgColor invertColor split /> */}
      </React.Fragment>
    );
  }
}

const modalFormStyle = {
  maxWidth: '320px',
  margin: '0 auto'
}
export default Home;